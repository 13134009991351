.methodology-page__div {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: calc(100% - 40px);
    max-width: 1000px;
    background-color: #F2F4F7;
    border-radius: 30px;
    overflow: hidden;
}

.methodology-page__header {
    background: rgb(229, 234, 242);
    background: linear-gradient(320deg, rgba(229, 234, 242, 1) 0%, rgba(163, 185, 236, 1) 45%, rgba(77, 88, 120, 1) 100%);
    position: relative;
    user-select: none;
    -webkit-user-drag: none;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.methodology-page__header-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
    background-image: url('../utils/images/header_methodology_image.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-origin: padding-box;
    width: calc(100% - 40px);
    height: 330px;
    padding: 0px 20px 40px 20px;
}

.methodology-page__back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 85px;
    height: 40px;
    min-height: 40px;
    border-radius: 12px;
    border: none;
    font-size: 16px;
}

.methodology-page__text-block {
    position: relative;
    max-width: 600px;
    text-align: left;
    color: #fff;
    display: flex;
    gap: 30px;
    flex-direction: column;
    z-index: 888;
}

.methodology-page__text-block h2 {
    font-size: 36px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.methodology-page__text-block p {
    font-size: 16px;
    line-height: 130%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5);
}

.methodology-page__main-block {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

.methodology-page__content-block {
    display: flex;
    padding: 20px;
    background-color: #fff;
    flex-direction: column;
    border-radius: 20px;
    gap: 20px;
    text-align: left;
}

.methodology-page__content-block h2 {
    font-size: 24px;
}

.methodology-page__content-block p {
    font-size: 16px;
}

.methodology-page__ul {
    list-style: none;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    line-height: 130%;
}

.methodology-page__ul li {
    position: relative;
    padding-left: 20px;
    font-size: 16px;
    line-height: 130%;
}

.methodology-page__ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 6px;
    height: 6px;
    border: 2px solid #002DFF;
    background-color: transparent;
}

.methodology-page__horizontal-block {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.methodology-page__list-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #EDF5FF;
    border-radius: 20px;
    padding: 20px;
}

.methodology-page__span {
    color: #002DFF;
    font-size: 16px;
}

.methodology-page__card-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: #EDF5FF;
    width: calc(100% - 40px);
}

.methodology-page__card__text-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.methodology-page__card-block h2 {
    font-size: 20px;
}

.methodology-page__card-block p {
    color: rgba(0, 0, 0, 0.70);
    line-height: 130%;
}

.methodology-page__card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.methodology-page__card-row p {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.50);
}

.methodology-page__duration-info {
    border-radius: 500px;
    border: 1px solid #000;
    background: #FFF;
    padding: 5px 20px;
    font-weight: bold;
}

.methodology-page__score-card {
    display: flex;
    padding: 20px;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    background: #EDF5FF;
}

.methodology-page__score-card h2 {
    font-size: 20px;
}

.methodology-page__score-card p {
    color: rgba(0, 0, 0, 0.50);
}

.methodology-page__score-card__list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.methodology-page__score-card__list-item {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.methodology-page__score-card__list-item p {
    color: #000;
}

.methodology-page__score-card__list-score {
    display: flex;
    width: 46px;
    height: 46px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    aspect-ratio: 1/1;
    border-radius: 10px;
    border: 2px solid #002DFF;
    background: #002DFF;
    color: #fff;
    font-weight: bold;
}

@media screen and (max-width: 769px) {
    .methodology-page__div {
        gap: 0px;
    }

    .methodology-page__main-block {
        padding: 10px;
    }

    .methodology-page__header-wrapper {
        padding: 0px 15px 30px 15px;
        width: calc(100% - 30px);
        margin-top: 15px;
    }

    .methodology-page__content-block {
        padding: 15px;
    }

    .methodology-page__header-wrapper {
        background-position: calc(100% + 200px) bottom;
    }

    .methodology-page__score-card {
        padding: 15px;
    }

    .methodology-page__card-block {
        padding: 15px;
    }
}

@media screen and (max-width: 865px) {
    .methodology-page__horizontal-block {
        flex-direction: column;
    }

    .methodology-page__div {
        width: calc(100% - 20px);
    }

}