.employee-module__block {
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
    padding: 15px;
    max-width: 900px;
    width: calc(100% - 80px);
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.employee-module__title {
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 16px;

}

.employee-module__header {
    width: calc(100% - 40px);
    position: relative;
    height: 55px;
    font-size: 14px;
    background-color: #6BC4E8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    color: #fff;
    align-items: center;
    padding: 0 20px;
}

.employee-module__div {
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.employee-module__subheader {
    width: calc(100% - 40px);
    height: 30px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 40% 10% 10% 180px 10%;
    padding: 0px 15px 0px 25px;
    border-radius: 8px;
    background-color: #F2F2F2;
    align-items: center;
    font-size: 14px;
}

.employee-module__item__status {
    color: #6BC4E8;
    font-weight: bold;
    text-decoration: none;
}

.employee-module__item {
    position: relative;
    width: calc(100% - 35px);
    height: 70px;
    display: grid;
    grid-template-columns: 40% 10% 10% 180px 10%;
    padding: 0 15px 0px 25px;
    border-radius: 10px;
    justify-content: space-between;
    transition: .3s ease-in-out;
    user-select: none;
    -webkit-user-drag: none;
    align-items: center;
    font-size: 14px;
    justify-items: center;
}

.employee-module__item__button {
    display: flex;
    align-items: center;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #6BC4E8;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    justify-content: center;
}

.employee-module__item__success {
    user-select: none;
    -webkit-user-drag: none;
}

.employee-module__item__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #000;
    gap: 5px;
}

.employee-module__item::before {
    position: absolute;
    left: 0px;
    content: '';
    width: 2px;
    height: 80%;
    background-color: #dde4eb;
    transition: .3s ease-in-out;
}

.employee-module__item:hover::before {
    background-color: #6BC4E8;
    height: 100%;
}

.employee-module__item__name-block {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
}

.employee-module__item__name {
    font-weight: bold;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    text-wrap: nowrap;
    overflow: hidden;
}

.employee-module__arrow {
    width: 15px;
    height: 15px;
}

.employee-module__item__post {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}

.employee-module__menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    color: #6BC4E8;
    position: absolute;
    right: 20px;
    border-radius: 10px;
    width: 250px;
    top: 50px;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
}

.employee-module__menu__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    text-align: left;
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
    padding: 0 10px;
}

.employee-module__menu__item_active {
    display: flex;
    align-items: center;
    background-color: #6BC4E8;
    color: #fff;
    height: 30px;
    border-radius: 10px;
    text-align: left;
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
    padding: 0 10px;
}

.employee-module__menu__item:hover {
    cursor: pointer;
    opacity: .7;
}

.employee-module__button {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    background-color: #fff;
    color: #6BC4E8;
    border-radius: 10px;
    width: 130px;
    position: relative;
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
    margin: 0;
    padding: 0px 10px;
}

.employee-module__button:hover {
    cursor: pointer;
    opacity: .7;
}

.pagination {
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.page-item {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: #fff;
    transition: .3s ease-in-out;
}

.page-link {
    border: none;
    background-color: transparent;
    user-select: none;
    -webkit-user-drag: none;
}

.page-item_active {
    background-color: #D9D9D9;
}

.page-link:hover {
    cursor: pointer;
    opacity: .7;
}

.page-item:hover {
    cursor: pointer;
    opacity: .7;
}

.quiz-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.empty-message {
    width: 100%;
    padding: 15px 0px;
}

@media screen and (max-width: 476px) {
    .employee-module__item {
        padding: 15px 20px;
        width: calc(100% - 40px);
    }
}

@media screen and (max-width: 769px) {
    .employee-module__button {
        width: 100px;
        height: 30px;
    }

    .employee-module__block {
        width: calc(100% - 40px);
        padding: 10px;
    }

    .employee-module__arrow {
        width: 15px;
    }

    .employee-module__item {
        font-size: 14px;
    }

    .score-module__header {
        font-size: 14px;
    }

    .employee-module__item__name-block {
        max-width: 50%;
    }

    .employee-module__item {
        display: flex;
        padding: 0 5px;
        width: calc(100% - 10px);
    }

    .employee-module__item__post {
        display: none;
    }

    .employee-module__item__status {
        text-align: center;
    }

    .employee-module__item::before {
        display: none;
    }

    .employee-module__subheader {
        display: flex;
    }

    #subheader-manager {
        display: none;
    }

    #subheader-employee {
        display: none;
    }

    .employee-module__item__success {
        display: none;
    }

    .employee-module__item__link p {
        display: none;
    }

    .employee-module__item__status {
        text-align: left;
        width: 100%;
    }
}