.quiz-body__div {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 20px 0 50px 0;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.quiz-body__cross {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    user-select: none;
}

.quiz-body__cross:hover {
    cursor: pointer;
}

.quiz-body__title {
    margin: 10px 0 0 0;
    transition: .3s ease-in-out;
}

.quiz-body__subtitle {
    font-size: 18px;
    margin: 10px 0 50px 0;
    transition: .3s ease-in-out;
}

.quiz-body__scores {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


.quiz-body__item {
    display: flex;
    width: 65px;
    height: 65px;
    border: 1px solid #000;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
    user-select: none;
    transition: .3s ease-in-out;
}

.quiz-body__item-selected {
    background-color: #000;
    color: #fff;
}

.quiz-body__item:hover {
    cursor: pointer;
}

.quiz-body__prompt {
    width: 650px;
    line-height: 25px;
    font-size: 18px;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    transition: .3s ease-in-out;
}

.quiz-body__input {
    width: 650px;
    text-align: center;
    margin-top: 50px;
    min-height: 150px;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    transition: .3s ease-in-out;
    font-size: 18px;
}

.quiz-body__input::placeholder {
    color: #000;
    font-size: 18px;
}

.quiz-body__buttons-div {
    display: flex;
    flex-direction: row;
}

.quiz-body__button {
    display: flex;
    font-size: 15px;
    width: 200px;
    height: 50px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}

.quiz-body__button:hover {
    cursor: pointer;
}

.popup-ask {
    display: flex;
    flex-direction: column;
    width: 512px;
    min-height: 300px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 30px;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.popup-ask__buttons {
    display: flex;
    flex-direction: row;
}

.popup-ask__cross {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 20px;
    user-select: none;
}

.popup-ask__cross:hover {
    cursor: pointer;
}

.popup-ask__button {
    width: 150px;
    height: 50px;
    color: #fff;
    background-color: #000;
    border-radius: 15px;
    border: none;
    font-size: 16px;
}

.popup-ask__button:first-of-type {
    margin-right: 30px;
}

.popup-ask__p {
    margin: 80px 0 80px 0;
    font-size: 20px;
}

.popup-ask__button_close {
    background-color: #fff;
    color: #000;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.popup-ask__button:hover {
    cursor: pointer;
}

.question-item__div-ne {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 930px;
    width: calc(100% - 30px);
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    gap: 10px;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.question-item__div {
    display: flex;
    flex-direction: column;
    max-width: 930px;
    width: calc(100% - 30px);
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    gap: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.question-item__div:last-of-type {
    margin-bottom: 0px;
}

.question-item__header {
    width: 100%;
    height: 55px;
    background-color: #6BC4E8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
}

.question-item__row {
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    background-color: #DAF0F9;
    border-radius: 10px;
}

.question-item__row-ne {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 33%;
    min-width: 250px;
    max-width: 100%;
    padding: 10px 20px;
    background-color: #DAF0F9;
    border-radius: 10px;
}

.question-item__row__scoreh {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 14px
}

.question-item__score-select {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    font-size: 14px;
}

.question-item__merged-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.question-item__single-score {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.question-item__score {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 65px;
    max-height: 65px;
    width: 50px;
    height: 50px;
    padding: 15px;
    border: none;
    border-radius: 15px;
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
    background-color: #fff;
    font-weight: bold;
    box-shadow: 0px 0px 0px 1px #d9d9d9 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #d9d9d9 inset;
    -moz-box-shadow: 0px 0px 0px 1px #d9d9d9 inset;
}

.question-item__score__title {
    color: #8e8d9b;
    text-align: left;
    margin-left: 25px;
    margin-top: 15px;
}

.question-item__score:disabled:hover {
    cursor: default;
    color: #d9d9d9;
    box-shadow: 0px 0px 0px 1px #d9d9d9 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #d9d9d9 inset;
    -moz-box-shadow: 0px 0px 0px 1px #d9d9d9 inset;
}

.question-item__score:hover {
    cursor: pointer;
    color: #6BC4E8;
    box-shadow: 0px 0px 0px 3px #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 3px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 3px #6BC4E8 inset;
}

.question-item__score_active {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border: none;
    border-radius: 15px;
    user-select: none;
    -webkit-user-drag: none;
    transition: .3s ease-in-out;
    background-color: #6BC4E8;
    color: #fff;
    font-weight: bold;
    box-shadow: none;
}

.question-item__score_active:hover {
    color: #fff;
}

.question-item__score_active:disabled:hover {
    box-shadow: none;
    color: #fff;
}

.question-item__score__description {
    width: 100%;
    padding: 20px 0px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.question-item__score__description p {
    line-height: 1.5;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    width: 100%;
}

.question-item__input {
    width: calc(100% - 20px);
    border-radius: 10px;
    resize: none;
    padding: 10px;
    border: none;
    line-height: 1.5;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    font-size: 14px;
}

.question-item__input:last-of-type {
    margin-bottom: 0px;
}

.question-item__input:focus {
    outline: none;
    box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
    -moz-box-shadow: 0px 0px 0px 1px #6BC4E8 inset;
}

.question-item__input::placeholder {
    font-size: 14px;
    color: #000;
}


@media screen and (max-width: 769px) {
    .question-item__row-ne {
        flex-direction: column;
        padding: 10px;
        width: calc(100% - 20px);
    }

    .question-item__single-score {
        width: 100%;
        justify-content: space-between;    
    }

    .question-item__merged-row {
        width: 100%;
    }

    .question-item__row {
        flex-direction: column;
        padding: 10px 15px;
    }

    .question-item__row:last-of-type {
        border-bottom: none;
    }

    .question-item__row p {
        width: 100%;
    }


    .question-item__score-select {
        gap: 6px;
        justify-content: center;
    }

    .question-item__score {
        width: 25%;
    }

    .question-item__div {
        padding: 10px;
        width: calc(100% - 20px);
        gap: 10px;
    }

    .question-item__row__scoreh {
        flex-direction: column;
        gap: 5px;
    }

    .question-item__score__description {
        padding: 10px 0;
    }

    #final-score {
        flex-direction: row !important;
    }

    #no-margin {
        margin: 0;
        width: auto;
    }
}