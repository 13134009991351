.employee-search__page {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: 150px;
    width: calc(100% - 20px);
    max-width: 1280px;
}

.employee-search__div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    width: calc(100% - 30px);
    border-radius: 20px;
    gap: 10px;
    background-color: #fff;
    transition: .3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.employee-search__main-div {
    margin-top: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 100%;
    border-radius: 20px;
    background-color: #fff;
    transition: .3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.employee-search__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
    gap: 10px;
}

.search-list__column__scores {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 50%;
    justify-content: center;
}

.employee-search__p {
    width: calc(100% - 20px);
}

.employee-search__input {
    border: 1px solid #DAF0F9;
    padding: 10px;
    border-radius: 10px;
    width: calc(100% - 20px);
    height: 30px;
    font-size: 15px;
    background-color: #fff;
    transition: .1s ease-in-out;
}

.employee-search__input:focus {
    outline: none;
    border: 1px solid #6BC4E8;
}

.employee-search__input::placeholder {
    font-size: 15px;
    color: #808080;
}

.employee-search__input:hover {
    border-color: #B5E2F4;
}


.employee-search__button {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6BC4E8;
}

.employee-search__list {
    width: 100%;
}

.employee-search__select-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 300px;
    gap: 10px;
}

.employee-search__item {
    gap: 10px;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    transition: .3s ease-in-out;
}

.employee-search__item p {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.employee-search__item_odd {
    gap: 10px;
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #D9D9D9;
    transition: .3s ease-in-out;
}

.employee-search__item_odd p {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.employee-search__item:hover {
    cursor: pointer;
    opacity: .7;
}

.employee-search__item_odd:hover {
    cursor: pointer;
    opacity: .7;
}

.ellipsis {
    width: 25px;
    height: 25px;
    line-height: 1.2;
    user-select: none;
    -webkit-user-drag: none;
}

.employee-search__cross {
    position: absolute;
    right: 25px;
    top: 30px;
    width: 25px;
    height: 25px;
    user-select: none;
    -webkit-user-drag: none;
}

.employee-search__cross:hover {
    cursor: pointer;
}

.employee-search__header {
    width: calc(100% - 40px);
    height: 15px;
    padding: 20px 20px;
    color: #fff;
    font-size: 15px;
    background-color: #6BC4E8;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 10px 0;
}

.employee-search__main-header {
    width: calc(100% - 40px);
    display: grid;
    padding: 10px 20px;
    grid-template-columns: repeat(3, 1fr) 150px;
    height: 60px;
    max-height: 60px;
    align-items: center;
    gap: 20px;
    text-align: left;
    border-bottom: 2px solid #6BC4E8;
}

.employee-search__calibrate-button {
    width: 150px;
    height: 50px;
    font-size: 14px;
    border-radius: 10px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
}

.search-list__row {
    display: grid;
    padding: 10px 20px;
    grid-template-columns: repeat(3, 1fr) 150px;
    height: 60px;
    max-height: 60px;
    width: calc(100% - 40px);
    align-items: center;
    gap: 20px;
    transition: .3s ease-in-out;
}

.search-list__row:last-of-type {
    border-radius: 20px;
}

.search-list__row__button {
    width: 100%;
    height: 50px;
    background-color: #6BC4E8;
    border-radius: 10px;
    border: none;
    color: #fff;
}

.search-list__column {
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    justify-content: space-around;
}

.null-answer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
}

#slclsc {
    flex-direction: row;
}


#emp-ph-col {
    width: 29%;
}

#emp-dp-col {
    width: 13%;
}

#emp-nm-col {
    width: 29%;
}

#emp-ps-col {
    width: 29%;
}

.calibrate-popup__layout {
    width: 100vw;
    height: 100vh;
    background-color: #0000006b;
    position: fixed;
    top: -52px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    padding-bottom: 60px;
}

.calibrate-popup__body {
    position: relative;
    max-width: 550px;
    width: calc(100% - 100px);
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -webkit-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    -moz-box-shadow: 0px 0px 0px 1px #dce1e6 inset;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.calibrate-popup__title {
    font-weight: bold;
    font-size: 16px;
}

.calibrate-popup__close-button {
    position: absolute;
    border: none;
    background-color: transparent;
    width: 30px;
    height: 30px;
    right: 15px;
    top: 15px;
}

.calibrate-popup__close-button img {
    height: 30px;
    width: 30px;
}

.calibrate-popup__body p {
    text-align: left;
}

.calibrate-popup__submit-button {
    width: 150px;
    height: 50px;
    border-radius: 10px;
    background-color: #6BC4E8;
    color: #fff;
    border: none;
}

.search-list__row:hover {
    cursor: pointer;
    background-color: #f0f2f5;
}

.search-module__toggle__div {
    display: flex;
    flex-direction: row;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.search-module__toggle__item {
    padding: 10px;
    background-color: #F2F2F2;
    color: #000;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
    transition: .3s ease-in-out;
    font-size: 14px;
}

.search-module__toggle__item_active {
    background-color: #6BC4E8;
    color: #fff;
    cursor: default;
    transition: .3s ease-in-out;
}

.employee-search__fullname {
    width: 33%;
    text-align: left;
}

.employee-search__department {
    width: 33%;
    text-align: left;
}

.employee-search__position {
    width: 33%;
    text-align: left;
}

.employee-search__header__div {
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    padding: 20px;
    border-bottom: 2px solid #6BC4E8;
}

.employee-search__header__div p {
    width: 33%;
    text-align: left;
}

.employee-search__item__div {
    display: flex;
    flex-direction: row;
    padding: 20px;
    height: 25px;
    align-items: center;
    gap: 20px;
    transition: .3s ease-in-out;
}

.employee-search__item__div:hover {
    background-color: #f0f2f5;
    cursor: pointer;
}

.search-module__button-div {
    display: flex;
    height: 40px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    border: none;
    background-color: transparent;
    font-size: 16px;
    z-index: 9999;
    border-radius: 12px;
    background: #FFF;
    align-self: flex-start;
}


@media screen and (max-width: 769px) {
    #emp-ph-col {
        display: none;
        width: 50%;
    }

    #emp-dp-col {
        display: none;

    }

    #emp-nm-col {
        width: 60%;
    }

    #emp-ps-col {
        width: 40%;
    }

    .employee-search__main-div {
        width: calc(100% - 20px);
    }

    .employee-search__div {
        width: calc(100% - 50px);
    }
}