.manager-analytics {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    gap: 20px;
    position: relative;
}

.manager-analytics__charts {
    width: 100%;
    display: flex;
    height: auto;
}

.manager-analytics__disclaimer {
    color: rgba(0, 0, 0, .5);
}

.manager-analytic__tooltip {
    max-width: 350px;
}

#manager-analytic__description {
    font-weight: bold;
    border-radius: 12px;
    background-color: #F2F4F7;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 20px;
}

@media screen and (max-width: 769px) {
    .manager-analytics {
        display: none;
    }
}